"use strict";

/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmFormatter = function () {
  "use strict";

  var normalizeSSN, formatSSN;

  normalizeSSN = function normalizeSSN(ssn, considerPlusHundredChar) {
    // returns ssn on the format XXXXXXXX[-|+]XXXX example: 19781003-0119 or false if format not valid.
    var now = new Date(),
        over100,
        withoutSeparator,
        yearBorn,
        yearToday,
        century,
        hundredPlusChar = "+";
    considerPlusHundredChar = typeof considerPlusHundredChar !== 'undefined' ? considerPlusHundredChar : true;
    over100 = ssn.indexOf(hundredPlusChar) === -1 ? false : true; //default: antar att personen inte är över 100 om + saknas(även om - också saknas).
    if (!considerPlusHundredChar) {
      hundredPlusChar = "-";
    }

    withoutSeparator = ssn.split(/-|\+/).join('');
    if (!isNaN(parseInt(withoutSeparator, 10))) {
      if (withoutSeparator.length === 10) {
        yearBorn = ssn.substring(0, 2);
        yearToday = now.getFullYear().toString().substr(2, 2);
        century = now.getFullYear().toString().substr(0, 2);
        if (parseInt(yearBorn, 10) > parseInt(yearToday, 10)) {
          //född förra seklet
          return (parseInt(century, 10) - 1).toString() + withoutSeparator.slice(0, 6) + "-" + withoutSeparator.slice(6, 10);
        }
        if (over100) {
          //född förra seklet
          return (parseInt(century, 10) - 1).toString() + withoutSeparator.slice(0, 6) + hundredPlusChar + withoutSeparator.slice(6, 10);
        }
        //född detta sekel
        return parseInt(century, 10).toString() + withoutSeparator.slice(0, 6) + "-" + withoutSeparator.slice(6, 10);
      }
      if (withoutSeparator.length === 12) {
        if (now.getFullYear() - parseInt(withoutSeparator.substring(0, 4), 10) >= 100) {
          over100 = true;
        }
        if (over100) {
          return withoutSeparator.slice(0, 8) + hundredPlusChar + withoutSeparator.slice(8, 12);
        }
        return withoutSeparator.slice(0, 8) + "-" + withoutSeparator.slice(8, 12);
      }
      return false;
    }
    return false;
  };

  formatSSN = function formatSSN(normalizedSSN) {
    return normalizedSSN;
  };

  return {
    load: function load() {},
    formatSSN: formatSSN,
    normalizeSSN: normalizeSSN
  };
}();
/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmGuiUtils = function () {
  "use strict";

  var windowHeight, setTargetBlank, addToTop, addTooltip, paddingBottomFixer, markTableRow, clickableTableRows, selectText, createSelectableText;

  windowHeight = $(window).height();

  setTargetBlank = function setTargetBlank() {
    $('a.external, a.pdf').attr('target', '_blank');
  };

  addToTop = function addToTop() {
    $('body').append("<i class='fa fa-arrow-circle-o-up fa-2x to-top hidden-xs'></i>");
    $('.to-top').hide();
    $('body').on('click', '.to-top', function (e) {
      $('body').animate({ scrollTop: 0 }, 'slow');
      e.preventDefault();
    });
    $(document).on('scroll', '', function () {
      var y = $(this).scrollTop();
      if (y > windowHeight) {
        $('.to-top').fadeIn();
      } else {
        $('.to-top').fadeOut();
      }
    });
  };

  addTooltip = function addTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
  };

  paddingBottomFixer = function paddingBottomFixer() {
    $("#navbar").on("click", "a[href^='#']", function () {
      var documentHeight, id, top, space, newPadding;
      $("body").css("padding-bottom", "");
      documentHeight = $(document).height();
      id = $(this).attr("href");
      if (id === "#") {
        return;
      }
      top = $(id).offset().top;
      space = documentHeight - top;
      if (space < windowHeight) {
        newPadding = windowHeight - space + 30; //30 is standard padding
        $("body").css("padding-bottom", newPadding);
        console.log("Padding is adjusted to: ", newPadding);
      }
    });
  };

  markTableRow = function markTableRow(tableRow) {
    tableRow.toggleClass('marked');
    var checkbox = tableRow.find('.row-checker');
    checkbox.prop("checked", tableRow.hasClass('marked'));
  };

  clickableTableRows = function clickableTableRows() {
    $('table.table-row-clickable').on('click', '> tbody > tr', function (e) {
      if (e.metaKey) {
        markTableRow($(this));
        e.stopPropagation();
        e.preventDefault();
      }
    });

    $('table.table input[type=checkbox].row-checker').on('change', null, function (e) {
      var tableRow = $(this).closest('tr');
      markTableRow(tableRow);
      e.stopPropagation();
      e.preventDefault();
    });

    $('table.table input[type=checkbox].row-checker-all').on('change', null, function () {
      var thisTable = $(this).closest('table.table');
      if (this.checked) {
        thisTable.find('input[type=checkbox].row-checker').prop('checked', true);
      } else {
        thisTable.find('input[type=checkbox].row-checker').prop('checked', false);
      }
    });
  };

  selectText = function selectText(el) {
    var range, sel;
    range = document.createRange();
    range.selectNodeContents(el);
    sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };

  createSelectableText = function createSelectableText() {
    $('.selectable').on('mouseup', '', function () {
      selectText(this);
      $(this).addClass('selected');
    });
  };

  return {
    load: function load() {
      setTargetBlank();
      addToTop();
      createSelectableText();
      clickableTableRows();
      paddingBottomFixer();
      addTooltip();
    }
  };
}();
/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmHelpText = function () {
  "use strict";

  var addOnClickShow, addOnClickClose, addHelpTextContainer;

  addOnClickShow = function addOnClickShow() {
    $('body').on('click', '[data-help-text]', function (e) {
      var helpTextHtml, helpTextSourceId;
      helpTextSourceId = $(this).attr("data-help-text");
      console.log(helpTextSourceId);
      helpTextHtml = $(helpTextSourceId).html().trim();
      $("#help-text-container > div.container > div.help-text").html(helpTextHtml);
      e.preventDefault();
    });
  };

  addOnClickClose = function addOnClickClose() {
    $('#help-text-container').on('click', '> div.container > button.close', function (e) {
      $('#help-text-container').collapse('hide');
      e.preventDefault();
    });
  };

  addHelpTextContainer = function addHelpTextContainer() {
    var helpTextContainer = "<div id='help-text-container' class='collapse help-text-container'><div class='container'><button type='button' class='close'><span >&times;</span><span class='sr-only'>Close</span></button><div class='help-text'></div></div></div>";
    $("body").append(helpTextContainer);
  };

  return {
    load: function load() {
      addHelpTextContainer();
      addOnClickShow();
      addOnClickClose();
      var helpTexts = $("[data-help-text]");
      helpTexts.attr("data-toggle", "collapse");
      helpTexts.attr("data-target", "#help-text-container");
    }
  };
}();
/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmMessages = function () {
  "use strict";

  var MESSAGE_TYPES,
      timeout = 4 * 1000,
      animationDuration = 400,
      createAlert,
      setType,
      hide,
      show;
  MESSAGE_TYPES = {
    SUCCESS: 1,
    INFO: 2,
    WARNING: 3,
    DANGER: 4
  };

  createAlert = function createAlert(text) {
    var element = $("<div class='alert'><button type='button' class='close'><span>&times;</span></button></div>");
    element.append(text);
    return element;
  };

  setType = function setType(element, type) {
    if (type === 1) {
      element.addClass("alert-success");
    } else if (type === 2) {
      element.addClass("alert-info");
    } else if (type === 3) {
      element.addClass("alert-warning");
    } else if (type === 4) {
      element.addClass("alert-danger");
    } else {
      element.addClass("alert-success");
    }
    return element;
  };

  hide = function hide(element) {
    element.hide(animationDuration);
  };

  show = function show(element, autoHide) {
    element.show(animationDuration);
    if (autoHide) {
      window.setTimeout(function () {
        hide(element);
      }, timeout);
    }
  };

  return {
    types: MESSAGE_TYPES,
    showMessage: function showMessage(text, type, autoHide) {
      var alert = createAlert(text);
      alert = setType(alert, type);
      $('#messages-container').html(alert);
      show(alert, autoHide);
    },
    appendMessage: function appendMessage(text, type, autoHide) {
      var alert = createAlert(text);
      alert = setType(alert, type);
      $('#messages-container').append(alert);
      show(alert, autoHide);
    },
    load: function load() {
      var messagesContainer = "<div id='messages-container' class='messages-container hidden-print'></div>";
      $("body").append(messagesContainer);
      $("body").on('click', '#messages-container > div.alert > button.close', function (e) {
        hide($(this).parent());
        e.preventDefault();
      });
    }
  };
}();

/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmMultipleValuesInput = function () {
  "use strict";

  var newInputCopyId, init;

  newInputCopyId = function newInputCopyId(id) {
    var parts, lastPartIndex;
    parts = id.split('-');
    lastPartIndex = parts.length - 1;
    if (parts[lastPartIndex - 1] === "c") {
      parts[lastPartIndex] = parseInt(parts[lastPartIndex], 10) + 1;
      return parts.join('-');
    }
    return parts.join('-') + "-c-1";
  };

  init = function init() {
    $('.form-horizontal div.multiple-values-container').on('click', ' > .multiple-values-input.action-form-show > .part > i.action-form-row.add-row', function () {
      var multObj, inputId, newMultObj, newId;
      multObj = $(this).closest('.multiple-values-input');
      inputId = multObj.find('input.form-control').attr('id');

      newMultObj = multObj.clone();
      multObj.find('i.action-form-row').removeClass('add-row').addClass('remove-row');

      newId = newInputCopyId(inputId);
      newMultObj.find('input.form-control').attr('id', newId).val('');
      newMultObj.find('label').attr('for', newId);
      newMultObj.removeClass('action-form-show');

      multObj.parent().append(newMultObj);
    });

    $('.form-horizontal div.multiple-values-container').on('click', ' > .multiple-values-input.action-form-show > .part > i.action-form-row.remove-row', function () {
      $(this).closest('.multiple-values-input').remove();
    });

    $('.form-horizontal div.multiple-values-container').on('input', 'input.form-control', function () {
      var multObj, isRemoveRow;
      multObj = $(this).closest('.multiple-values-input');
      isRemoveRow = multObj.find('i.action-form-row').hasClass('remove-row');
      if ($(this).val().length !== 0) {
        multObj.addClass('action-form-show');
      } else {
        if (isRemoveRow) {
          multObj.remove();
        } else {
          multObj.removeClass('action-form-show');
        }
      }
    });
  };

  return {
    load: function load() {
      init();
    }
  };
}();

/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmPersist = function () {
  "use strict";

  var detectLocalStorageSupport,
      localStorageSupport = false,
      defaultLocalStorageNamespace,
      setLocalStorageNamespace,
      saveNameValue,
      loadNameValue,
      saveNameValueJSON,
      loadNameValueJSON,
      formPersistSave,
      formPersistLoad;

  detectLocalStorageSupport = function detectLocalStorageSupport() {
    var mod = "test";
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      localStorageSupport = true;
    } catch (exception) {
      localStorageSupport = false;
    }
    if (!localStorageSupport) {
      console.error("This browser doesn't support LocalStorage.");
    }
  };

  saveNameValue = function saveNameValue(k, v, namespace) {
    if (!k.trim() || !v.trim()) {
      return;
    }
    if (namespace) {
      k = namespace + "." + k;
    } else if (defaultLocalStorageNamespace) {
      k = defaultLocalStorageNamespace + "." + k;
    }
    localStorage.setItem(k, v);
  };

  saveNameValueJSON = function saveNameValueJSON(k, v_json, namespace) {
    saveNameValue(k, JSON.stringify(v_json), namespace);
  };

  loadNameValue = function loadNameValue(k, namespace) {
    if (!k.trim()) {
      return null;
    }
    if (namespace) {
      k = namespace + "." + k;
    } else if (defaultLocalStorageNamespace) {
      k = defaultLocalStorageNamespace + "." + k;
    }
    return localStorage.getItem(k);
  };

  loadNameValueJSON = function loadNameValueJSON(k, namespace) {
    return JSON.parse(loadNameValue(k, namespace));
  };

  setLocalStorageNamespace = function setLocalStorageNamespace(namespace) {
    defaultLocalStorageNamespace = namespace;
  };

  formPersistSave = function formPersistSave() {
    console.info("Saving form values.");
    var inputs = document.querySelectorAll("input[data-mm-form-persist], textarea[data-mm-form-persist]"),
        k,
        v,
        i;
    for (i = 0; i < inputs.length; i = i + 1) {
      k = inputs[i].getAttribute("data-mm-form-persist");
      v = inputs[i].value;
      saveNameValue(k, v);
    }
  };

  formPersistLoad = function formPersistLoad() {
    console.info("Loading persisted form values.");
    var inputs = document.querySelectorAll("input[data-mm-form-persist], textarea[data-mm-form-persist]"),
        k,
        v,
        i;

    for (i = 0; i < inputs.length; i = i + 1) {
      k = inputs[i].getAttribute("data-mm-form-persist");
      v = loadNameValue(k);
      inputs[i].value = v;
    }
  };

  return {
    load: function load() {
      detectLocalStorageSupport();
    },
    saveNameValue: saveNameValue,
    loadNameValue: loadNameValue,
    saveNameValueJSON: saveNameValueJSON,
    loadNameValueJSON: loadNameValueJSON,
    setLocalStorageNamespace: setLocalStorageNamespace,
    formPersistSave: formPersistSave,
    formPersistLoad: formPersistLoad
  };
}();

/*jslint indent: 2 */
/*jslint browser: true */
/*global $*/
var mmValidator = function () {
  "use strict";

  var getYear, getCheckSum, isDate, validateSsnSubtractDays, validateSSN, validateSamordningsnummer, validateOrgnr;

  getYear = function getYear(y) {
    return y < 1000 ? y + 1900 : y;
  };

  getCheckSum = function getCheckSum(numbers) {
    var n,
        checkSum = 0;
    for (var i = 0; i <= 9; i += 1) {
      n = parseInt(numbers[i], 10);
      if (i % 2 === 0) {
        checkSum += n * 2 % 9 + Math.floor(n / 9) * 9;
      } else {
        checkSum += n;
      }
    }
    return checkSum;
  };

  isDate = function isDate(year, month, day) {
    month = month - 1;
    var tmpDate = new Date(year, month, day);
    if (getYear(tmpDate.getYear()) === parseInt(year, 10) && parseInt(month, 10) === tmpDate.getMonth() && parseInt(day, 10) === tmpDate.getDate()) {
      return true;
    }
    return false;
  };

  validateSsnSubtractDays = function validateSsnSubtractDays(ssn, daysToSubtract) {
    var now = new Date(),
        yearBorn,
        yearToday,
        over100,
        century,
        hyphenChar,
        numbers,
        checkSum;
    if (typeof ssn !== "string") {
      return false;
    }

    ssn = ssn.trim();

    numbers = ssn.match(/^(18|19|20)?\d{6}(-)?\d{4}$/);
    if (numbers === null) {
      numbers = ssn.match(/^(18|19|20)?\d{6}(\+)?\d{4}$/);
      if (numbers === null) {
        return false;
      }
      over100 = true;
    }

    if (ssn.length === 10 || ssn.length === 11) {
      // Century is missing, adding it...
      yearBorn = ssn.substring(0, 2);
      yearToday = now.getFullYear().toString().substr(2, 2);
      century = "";
      if (parseInt(yearBorn, 10) > parseInt(yearToday, 10)) {
        century = over100 ? "18" : "19";
      } else {
        century = over100 ? "19" : "20";
      }
      ssn = century + ssn;
    }

    if (ssn.length === 13) {
      // There is a hyphen, check and remove it
      hyphenChar = ssn.slice(8, 9);
      if (hyphenChar !== '-' && hyphenChar !== '+') {
        return false;
      }
      ssn = ssn.slice(0, 8) + ssn.slice(9, ssn.length);
    }

    if (ssn.length !== 12) {
      return false;
    }

    var birthDay = ssn.substring(6, 8) - daysToSubtract;
    if (!isDate(ssn.substring(0, 4), ssn.substring(4, 6), birthDay)) {
      return false;
    }

    // Remove century
    ssn = ssn.slice(2, ssn.length);

    checkSum = getCheckSum(ssn.split(''));

    return checkSum % 10 === 0;
  };

  validateSSN = function validateSSN(ssn) {
    return validateSsnSubtractDays(ssn, 0);
  };

  validateSamordningsnummer = function validateSamordningsnummer(ssn) {
    return validateSsnSubtractDays(ssn, 60);
  };

  validateOrgnr = function validateOrgnr(orgnr) {
    var numbers, checkSum, firstTwoDigits, charAtHyphenPosition;
    if (typeof orgnr !== "string") {
      return false;
    }
    orgnr = orgnr.trim();
    if (orgnr.length === 12 || orgnr.length === 13) {
      // Check and remove first two digits
      firstTwoDigits = orgnr.slice(0, 2);
      if (firstTwoDigits !== '16') {
        return false;
      }
      orgnr = orgnr.slice(2, orgnr.length);
    }
    if (orgnr.length === 11) {
      // Check and remove hyphen
      charAtHyphenPosition = orgnr.slice(6, 7);
      if (charAtHyphenPosition !== '-') {
        return false;
      }
      orgnr = orgnr.slice(0, 6) + orgnr.slice(7, orgnr.length);
    }
    if (orgnr.length !== 10) {
      return false;
    }

    numbers = orgnr.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
    if (numbers === null) {
      return false;
    }

    checkSum = getCheckSum(orgnr.split(''));

    return checkSum % 10 === 0;
  };

  return {
    load: function load() {},
    validateSSN: validateSSN,
    validateSamordningsnummer: validateSamordningsnummer,
    validateOrgnr: validateOrgnr
  };
}();