'use strict';

angular.module('mmMessages', ['pascalprecht.translate']).directive('messageHandler', ['$window', '$timeout', function ($window, $timeout) {
  return {
    template: '<div id="mm-message" class="mm-message" ng-show="hasMessages">' + '<div class="alert alert-danger" ng-repeat="error in errors track by error.id">' + '<span>' + '<p><strong>{{error.title | translate }}</strong>' + '<span class="pull-right pointer" style="display: inline-block;">' + '<i class="fa fa-times"  ng-click="resolveMessage($event, error.name)"></i></span></p>' + '<p>{{error.message | translate }}</p>' + '</span>' + '</div>' + '<div class="alert alert-warning" ng-repeat="warning in warnings track by warning.id">' + '<span>' + '<p><strong>{{warning.title | translate }}</strong>' + '<span class="pull-right pointer" style="display: inline-block;">' + '<i class="fa fa-times"  ng-click="resolveMessage($event, warning.name)"></i></span></p>' + '<p>{{warning.message | translate }}</p>' + '</span>' + '</div>' + '<div class="alert alert-success" ng-repeat="success in successMessages track by success.id">' + '<span>' + '<p><strong>{{success.title | translate }}</strong></p>' + '<p>{{success.message | translate }}</p>' + '</span>' + '</div>' + '</div>',
    scope: {
      successDuration: '@'
    },
    replace: true,
    controller: ['$scope', function controller($scope) {
      var _window = angular.element($window);
      var FOUR_SECONDS = 4000;

      $scope.messageId = 0;

      $scope.errors = [];
      $scope.warnings = [];
      $scope.successMessages = [];

      $scope.hasMessages = false;
      $scope.successDuration = parseInt($scope.successDuration);
      $scope.duration = _.isNumber($scope.successDuration) && !_.isNaN($scope.successDuration) ? parseInt($scope.successDuration) : FOUR_SECONDS;

      $scope.resolveMessage = function (e, data) {
        var messages = _.isArray(data) ? data : [data];
        var mess;
        var errorsCopy, warningsCopy, successCopy;

        function matchMessage(message) {
          return message.name === mess;
        }

        if (messages && messages.length) {
          errorsCopy = angular.copy($scope.errors);
          warningsCopy = angular.copy($scope.warnings);
          successCopy = angular.copy($scope.successMessages);

          _.each(messages, function (m) {
            mess = m;
            var error = _.find(errorsCopy, matchMessage);
            var warning = _.find(warningsCopy, matchMessage);
            var success = _.find(successCopy, matchMessage);

            removeMessage(errorsCopy, error);
            removeMessage(warningsCopy, warning);
            removeMessage(successCopy, success);
          });

          $scope.hasMessages = errorsCopy.length > 0 || successCopy.length > 0;
          $scope.errors = errorsCopy;
          $scope.warnings = warningsCopy;
          $scope.successMessages = successCopy;
        }
      };

      function setHasMessages() {
        $scope.hasMessages = $scope.errors.length > 0 || $scope.warnings.length > 0 || $scope.successMessages.length > 0;
      }

      $scope.$on('mm-message', function (e, message) {
        if (message.title != '' && !message.title) {
          return;
        }

        message.id = ++$scope.messageId;

        categorize(message);
        _window.trigger('mm-message-added');
        setFadeSuccess(message);
      });

      $scope.$on('mm-resolve-message', $scope.resolveMessage);

      $scope.$on('mm-clear-messages', function () {
        $scope.errors = [];
        $scope.successMessages = [];
      });

      function removeMessage(arr, message) {
        if (message) {
          arr.splice(arr.indexOf(message), 1);
        }
      }

      function categorize(message) {
        if (message.type === 'error' && isNewMessage(message, $scope.errors)) {
          $scope.errors.push(message);
          setHasMessages();
        } else if (message.type === 'warning' && isNewMessage(message, $scope.warnings)) {
          $scope.warnings.push(message);
          setHasMessages();
        } else if (message.type === 'success' && isNewMessage(message, $scope.successMessages)) {
          $scope.successMessages.push(message);
          setHasMessages();
        }
      }

      function isNewMessage(message, arr) {
        return _.filter(arr, function (e) {
          return angular.equals(e.title, message.title) && angular.equals(e.message, message.message);
        }).length === 0;
      }

      function setFadeSuccess(message) {
        if (message.type === 'success') {
          $timeout(function () {
            var eventMock = {};
            $scope.resolveMessage(eventMock, message.name);
          }, $scope.duration);
        }
      }
    }], link: function link(scope, element) {
      var pos,
          fixedClassName = 'mm-message-fixed',
          _window = angular.element($window),
          errorDiv = angular.element(element),
          setByPsErrorAdded = false,
          psErrorAddedEvent = 'mm-message-added';

      function toggleErrorFixed(e) {
        var top = _window.scrollTop(),
            type = e.type;

        if (type === psErrorAddedEvent && top > 150) {
          setByPsErrorAdded = true;
          errorDiv.addClass(fixedClassName);
        } else {
          pos = setByPsErrorAdded ? undefined : pos;
          setByPsErrorAdded = false;
          errorDiv.removeClass(fixedClassName);

          if (errorDiv.length > 0 && errorDiv.is(':visible') && pos === undefined) {
            pos = errorDiv.offset().top;
          }

          if (top > pos) {
            errorDiv.addClass(fixedClassName);
          } else {
            errorDiv.removeClass(fixedClassName);
          }
        }
      }

      _window.on('scroll mm-message-added', toggleErrorFixed);

      scope.$on('destroy', function () {
        _window.off('scroll', toggleErrorFixed).off('mm-message-added', toggleErrorFixed);
      });
    }
  };
}]).factory('Message', ['$rootScope', function ($rootScope) {
  var service = {
    error: function error(title, message, name) {
      $rootScope.$broadcast('mm-message', { type: 'error', title: title, message: message, name: name });
    },
    success: function success(title, message, name) {
      $rootScope.$broadcast('mm-message', { type: 'success', title: title, message: message, name: name });
    },
    warning: function warning(title, message, name) {
      $rootScope.$broadcast('mm-message', { type: 'warning', title: title, message: message, name: name });
    },
    resolveMessage: function resolve() {
      var names = Array.prototype.slice.call(arguments);
      $rootScope.$broadcast('mm-resolve-message', names);
    },
    clearMessages: function clear() {
      $rootScope.$broadcast('mm-clear-messages');
    }
  };

  $rootScope.$on('$routeChangeStart', service.clearMessages);

  return service;
}]);

angular.module('mmTableComponents', []).directive('mmTablesorter', function () {
  return {
    restrict: 'A',
    transclude: true,
    template: '<div class="sortable" ng-click="onClick()">' + '<nobr><span ng-transclude></span>' + '&nbsp;<i class="fa no-print" ng-class="{\'fa-sort-asc\': order === by && !reverse,  \'fa-sort-desc\' : order===by && reverse, \'fa-sort\': order!==by}"></i>' + '</nobr>' + '</div>',
    scope: {
      order: '=',
      by: '=',
      reverse: '='
    },
    link: function link(scope, element, attrs) {
      scope.onClick = function () {
        if (scope.order === scope.by) {
          scope.reverse = !scope.reverse;
        } else {
          scope.by = scope.order;
          scope.reverse = false;
        }
      };
    }
  };
});